import React, { Component, useRef, useState } from "react"
import _, { defaultTo, shuffle, isEqual } from "lodash"
import axios from "axios"

import CONFIG from "config"

import SprintBar from "components/SprintBar"
import LoginScreen from "screens/LoginScreen"
import MemoryExercise from "screens/MemoryExercise/MemoryExercise"
import ShootingExercise from "screens/ShootingExercise/ShootingExercise"
import OpenQuestionExercise from "screens/OpenQuestionExercise/OpenQuestionExercise"
import MessageScreen, { FinishMessage } from "screens/MessageScreen/MessageScreen"
import SummaryScreen from "screens/SummaryScreen"
import RiseFallExercise from "screens/RiseFallExercise/RiseFallExercise"

import PlayArea from "lib/PlayArea"
import SendResultsScreen from "screens/SendResultsScreen"
import Results from "structures/Results"
import Animation, { ANIMATION_TYPES } from "components/Animation"
import TilesExercise from "exercises/components/QuizExercise/TilesExercise"
import TrainExercise, { POINTS_FOR_FIRST_ANSWER } from "screens/TrainExercise/TrainExercise"
import ChoicesExercise from "screens/ChoicesExercise/ChoicesExercise"
import RevealExercise from "screens/RevealExercise/RevealExercise"
import ElevatorExercise from "screens/ElevatorExercise/ElevatorExercise"
import DoorsExercise from "screens/DoorsExercise/DoorsExercise"
import MatchExercise from "screens/MatchExercise/MatchExercise"
import CauldronExercise from "screens/CauldronExercise"
import VotingExercise from "screens/VotingExercise/VotingExercise"
import nestStringProperties from "../utils/nestStringProperties"
import ParachutesExercise from "exercises/components/ParachutesExercise/ParachutesExercise"

import "./Sprint.scss"
import clsx from "clsx"
import SprintArea from "./subcomponents/SprintArea"
import Exercise from "../@exercises/Exercise"
import getConfig from "@exercises/configs"
import CookieConsent from "react-cookie-consent"
import ReactGA from "react-ga"

import { useTranslation } from "react-i18next"
import AnimatedElement from "../components/AnimatedElement/AnimatedElement"
import { useEffectOnce } from "react-use"
import SzymonVotingExercise from "../screens/VotingExercise/variants/SzymonVotingExercise"
import TeamDysfunctionsVotingExercise from "../screens/VotingExercise/variants/TeamDysfunctionsVotingExercise"

function deepen(o) {
  let oo = {},
    t,
    parts,
    part
  for (let k in o) {
    t = oo
    parts = k.split(".")
    let key = parts.pop()
    while (parts.length) {
      part = parts.shift()
      t = t[part] = t[part] || {}
    }
    t[key] = o[k]
  }
  return oo
}

export const GA_CATEGORIES = {
  auth: "auth",
  exercise: "exercise",
  results: "results",
  summary: "summary",
  finished: "finished",
}

const FIXED_PARAMETERS = {
  token: "bubbles#35143",
  shouldRequireLogin: false,
  shouldRequirePassword: false,
  shouldPlayInLoop: false,
  shouldSendResults: true,
  shouldShowSummary: false,
  // exerciseGroups: EXAMPLE_CONFIG,
}

const USE_FIXED_PARAMETERS = false

export const WIDTH_EMS = 80
export const WIDTH_EMS_VERTICAL = 47
export const DEFAULT_ANIMATION_SPEED = 1000

export const SERVER_ADDRESS = CONFIG.SERVER_ADDRESS
export const INSTANCE_NAME = CONFIG.INSTANCE_NAME
export const SESSION_ID = CONFIG.SESSION_ID

// DO NOT DELETE! Needed to make global hotkey to skip components
let CHANGE_COMPONENT = null

const STARTING_LEVEL = 0

const LOGIN_COMPONENT = {
  type: "login",
  parameters: {
    helpText: "Wprowadź nazwę użytkownika i hasło, które otrzymałeś.",
  },
}

const SUMMARY_COMPONENT = {
  id: "summary",
  slug: "summary",
  type: "summary",

  parameters: {
    helpText:
      'Na tym ekranie możesz obejrzeć swoje wyniki z całego sprintu. Jeśli chcesz powtórzyć któreś ze ćwiczeń, kliknij na przycisk "Powtórz ćwiczenie" w wierszu z danym ćwiczeniem.',
  },
}

const SEND_RESULTS_COMPONENT = {
  id: "send_results",
  type: "send_results",
  parameters: {
    helpText:
      'Wysyłamy statystyki - poczekaj, aż zakończymy. W razie problemów wybierz opcję "Spróbuj ponownie" lub skontaktuj się z nami.',
  },
}

const DEFAULT_FINISH_PARAMETERS = {
  image: "finish",
  header: "Dziękujemy!",
  content:
    "To już koniec - dziękujemy za poświęcony czas. Mamy nadzieję, że spędziłeś go miło i pożytecznie.",
  buttonText: "Odśwież",
  refreshOnNext: true,
  helpText: "To już koniec! Możesz zamknąć okno przeglądarki.",
}

const ALREADY_PLAYED_COMPONENT = {
  id: "message_already_played",
  slug: "message",
  parameters: {
    header: "Zakończono",
    image: "finish",
    content: "Ta sesja jest już zakończona - nie możesz jej przejść jeszcze raz.",
    buttonText: "Odśwież",

    helpText: "To już koniec! Możesz zamknąć okno przeglądarki.",
    refreshOnNext: true,
  },
}

export const SprintCookieConsent = () => {
  const { t } = useTranslation("common")
  const nonInvasive = CONFIG.NONINVASIVE_CONSENT
  const [isVisible, setIsVisible] = useState(true)
  const consentRef = useRef()
  const consentTimeoutRef = useRef()

  useEffectOnce(() => {
    if (nonInvasive) {
      consentTimeoutRef.current = setTimeout(() => {
        if (consentRef.current) {
          consentRef.current.accept()
        }
      }, 10000)
    }

    return () => {
      clearTimeout(consentTimeoutRef.current)
    }
  })

  return (
    <AnimatedElement visible={isVisible} durationMs={500} appearDelayMs={1000}>
      <CookieConsent
        ref={consentRef}
        location="none"
        containerClasses={clsx("CookieConsent", { "non-invasive": nonInvasive })}
        overlay={!nonInvasive}
        overlayClasses="consent-overlay"
        buttonText={t("cookies.button")}
        disableStyles={true}
        contentClasses="content"
        hideOnAccept={false}
        onAccept={() => {
          clearTimeout(consentTimeoutRef.current)
          setIsVisible(false)
        }}
      >
        {nonInvasive ? t("cookies.message_noninvasive") : t("cookies.message")}
      </CookieConsent>
    </AnimatedElement>
  )
}

class Sprint extends Component {
  instanceName = INSTANCE_NAME
  sessionId = SESSION_ID
  sessionToken = ""
  doubleTapControlled = false
  touchScrolling = false
  lastTouchEnd = 0
  lastEvent = {}
  forceLogin = false

  canRepeatExercises = null
  instanceScore = []

  parameters = {}

  sprintAreaRef

  constructor(props) {
    super(props)

    if (CONFIG.GA_ID) {
      ReactGA.initialize(CONFIG.GA_ID, {
        debug: false,
        titleCase: false,
      })

      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    this.state = {
      initialised: false,

      forcedComponent: false,

      height: 0,
      width: 0,
      fontSize: 0,

      loggedIn: null,
      passwordRequired: null,
      playInLoop: null,
      shouldSendResults: null,
      shouldShowSummary: null,
      generateLogin: false,
      exerciseGroups: [],
      otherParameters: {},

      finishParameters: DEFAULT_FINISH_PARAMETERS,

      helpVisible: false,
      skipVisible: false,
      points: {},
      otherResults: {},
      events: {},
      currentPoints: 0,

      maxPoints: 0,

      level: STARTING_LEVEL,
      sprintFinished: false,
      replaying: false,
      showFinish: false,
      showSummary: false,
      sendResults: false,
    }

    if (props.match.params.instanceName && isNaN(props.match.params.instanceName)) {
      this.instanceName = props.match.params.instanceName
      this.sessionId = undefined
    }

    if (props.match.params.sessionId) {
      this.sessionId = props.match.params.sessionId
    } else if (!isNaN(props.match.params.instanceName)) {
      this.sessionId = props.match.params.instanceName
    }

    this.disableTapZooming()

    this._onLoggedIn = this._onLoggedIn.bind(this)
    this._onNextScreen = this._onNextScreen.bind(this)
    this._onResize = this._onResize.bind(this)
    this._getExerciseComponent = this._getExerciseComponent.bind(this)

    this.sprintAreaRef = React.createRef()

    CHANGE_COMPONENT = this.changeComponent
  }

  disableTapZooming = () => {
    document.addEventListener(
      "touchmove",
      (event) => {
        if (!this.doubleTapControlled && !this.targetScrollable(event.target)) {
          event.preventDefault()
        }
      },
      { passive: false }
    )

    document.addEventListener(
      "touchend",
      (event) => {
        if (!this.doubleTapControlled) {
          let now = new Date().getTime()
          if (now - this.lastTouchEnd <= 300) {
            event.preventDefault()
          } else {
            this.lastTouchEnd = now
          }
        }
      },
      { passive: false }
    )
  }

  targetScrollable = (target) => {
    return (
      target.classList.contains("scrollable") ||
      target.parentElement.classList.contains("scrollable") ||
      target.parentElement.parentElement.classList.contains("scrollable") ||
      target.parentElement.parentElement.parentElement.classList.contains("scrollable")
    )
  }

  _getSprintOptions = () => {
    let username = window.localStorage.getItem("username")
    let token = window.localStorage.getItem("token")

    if (USE_FIXED_PARAMETERS) {
      console.log("!!! WARNING: Using fixed parameters !!!")
      this.instanceScore = 0
      setTimeout(this._parametersLoaded.bind(this, FIXED_PARAMETERS), 1000)
    } else {
      axios({
        method: "POST",
        url: SERVER_ADDRESS + "get-active-session",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          username: username,
          userToken: token,
          instanceName: this.instanceName,
          sessionId: this.sessionId,
        },
      })
        .then((result) => {
          if (result.data["status"] === "Success") {
            // SUCCESS
            let parameters = deepen(result.data["parameters"])
            this.instanceScore = result.data["instanceScore"]
            this._parametersLoaded(parameters)
          } else if (result.data["status"] === "Already played") {
            this.sendGAEvent("general", "Sprint already played")
            this.setState({
              forcedComponent: ALREADY_PLAYED_COMPONENT,
              initialised: true,
            })
            this.changeComponent(false)
          } else {
            // ERROR
            console.log("error")
            // TODO Handle error
          }
        })
        .catch((error) => {
          // CONNECTION ERROR
          console.log("connection error")
          console.log(error)
          // TODO Handle connection error
        })
    }
  }

  _parametersLoaded = (parameters) => {
    this.parameters = parameters.other

    let maxPoints = 0
    let points = {}
    let levelIndex = 0

    for (let exercise of parameters["exercises"]) {
      let currentPoints = {
        current: 0,
        max: 0,
      }

      if (exercise.parameters && !exercise.parameters.tutorial) {
        let pointsForExercise = this._calculatePointsForExercise(exercise)
        currentPoints["max"] = pointsForExercise
        maxPoints += pointsForExercise
      }

      points[levelIndex] = currentPoints
      levelIndex++
    }

    this.sessionToken = parameters["token"]
    if (this.instanceScore.length > 0) {
      this.canRepeatExercises = false
    } else {
      this.canRepeatExercises = parameters["canRepeatExercises"]
    }

    let exercises = parameters["exercises"]
    if (this.parameters["shuffleExercises"]) {
      const length = exercises.length
      const [startExercise, finishExercise] = this.parameters.shuffleExercises
      const exercisesStart = startExercise === 0 ? [] : exercises.slice(0, startExercise)
      const exercisesShuffled = shuffle(exercises.slice(startExercise, finishExercise + 1))
      const exercisesFinish =
        finishExercise === length - 1 ? [] : exercises.slice(finishExercise + 1, length)
      exercises = [...exercisesStart, ...exercisesShuffled, ...exercisesFinish]
    }

    for (let exercise of exercises) exercise.parameters = deepen(exercise.parameters)

    let otherParameters = parameters["other"] ? parameters["other"] : {}
    const finishParameters = defaultTo(otherParameters["finishParameters"], {
      image: "finish",
      refreshOnNext: true,
      helpText: "To już koniec! Możesz zamknąć okno przeglądarki.",
    })

    this.forceLogin = this.parameters.forceLogin ?? false

    this.setState({
      initialised: true,

      loggedIn: !parameters["shouldRequireLogin"] && !parameters["shouldSendResults"],
      passwordRequired: parameters["shouldRequirePassword"],
      generateLogin: !parameters["shouldRequireLogin"] && parameters["shouldSendResults"],
      playInLoop: parameters["shouldPlayInLoop"],
      shouldSendResults: parameters["shouldSendResults"],
      shouldShowSummary: parameters["shouldShowSummary"],
      exerciseGroups: exercises,

      finishParameters,

      otherParameters,

      points: points,
      maxPoints: maxPoints,
    })

    this.changeComponent(false)
  }

  _getComponent = (state = this.state) => {
    if (state.forcedComponent) {
      return state.forcedComponent
    } else if (!state.exerciseGroups) {
      return false
    } else if (!state.loggedIn) {
      this.sendGAEvent("auth", "Logging in")
      return LOGIN_COMPONENT
    } else if (state.sprintFinished && !state.replaying) {
      if (state.showFinish) {
        return this._getFinishComponentOptions()
      } else if (state.sendResults && this.canRepeatExercises) {
        return this._sendResults()
      } else if (state.showSummary) {
        this.sendGAEvent("general", "Shown summary")
        return SUMMARY_COMPONENT
      } else if (state.sendResults) {
        return this._sendResults()
      } else {
        return this._getFinishComponentOptions()
      }
    } else {
      const exercise = state.exerciseGroups[state.level]
      this.sendGAEvent("exercise", "Exercising", exercise.slug, parseInt(exercise.id))
      return exercise
    }
  }

  _sendResults = () => {
    this.sendGAEvent("general", "Sending results")
    return SEND_RESULTS_COMPONENT
  }

  _getFinishComponentOptions = () => {
    this.sendGAEvent("general", "Sprint finished")

    return {
      id: "message_finish",
      slug: "finish_message",
      parameters: this.state.finishParameters,
    }
  }

  _calculatePointsForExercise = (exercise) => {
    let type = exercise["slug"]
    const { parameters, questions } = exercise
    let count = 0

    switch (type) {
      case "memory":
        return MemoryExercise.maxPoints(exercise.questions, exercise.parameters)
      case "question":
        return 10
      case "tiles":
        return TilesExercise.WrappedComponent.maxPoints(exercise.questions, parameters)
      case "shooting":
        return ShootingExercise.WrappedComponent.maxPoints(questions, parameters)
      case "train":
        return (
          exercise.questions.length *
          (exercise.parameters.pointsForFirstAnswer
            ? exercise.parameters.pointsForFirstAnswer
            : POINTS_FOR_FIRST_ANSWER)
        )
      case "rise_fall":
        return RiseFallExercise.WrappedComponent.maxPoints(exercise.questions)
      case "memory_match":
        return parameters.answers.length
      case "match":
        return MatchExercise.WrappedComponent.maxPoints(exercise.questions)
      case "cauldron":
        return CauldronExercise.WrappedComponent.maxPoints(questions, parameters)
      case "questions":
        for (let question of parameters.questions) count += question.subquestions.length
        return count
      case "reveal":
        return RevealExercise.WrappedComponent.maxPoints(exercise.questions)
      case "elevator":
        return ElevatorExercise.WrappedComponent.maxPoints(questions, parameters)
      case "doors":
        return DoorsExercise.WrappedComponent.maxPoints(questions, parameters)
      case "parachutes":
        return ParachutesExercise.WrappedComponent.maxPoints(exercise.questions)
      case "cleaning":
      case "puzzle":
      case "true_false":
      case "bubbles":
      case "conversations":
        const config = getConfig(exercise)
        return config.maxPoints(config)
      case "open_question":
      default:
        return 0
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this._getSprintOptions()
    this._onResize()
    window.addEventListener("resize", this._onResize)
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this._onResize)
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.fontSize !== nextState.fontSize) {
      document.documentElement.style.fontSize = `${nextState.fontSize}px`
    }
    return true
  }

  render() {
    let component
    if (this.state.initialised) {
      component = this._getComponent()
    } else {
      return <LoadingSprint />
    }

    let exerciseComponent = this._getExerciseComponent(component)
    const { otherParameters } = this.state

    return (
      <div
        className={clsx("Sprint", {
          vertical: PlayArea.isVertical(),
          horizontal: !PlayArea.isVertical(),
        })}
        ref={this.sprintAreaRef}
      >
        <SprintArea
          slug={component["slug"]}
          className={clsx("game", "scrollable", component["slug"])}
          style={{
            fontSize: this.state.fontSize,
            width: this.state.width,
            height: this.state.height,
          }}
        >
          {exerciseComponent !== "exercise" ? (
            exerciseComponent
          ) : (
            <Exercise exercise={component} key={component.id} onFinish={this._onNextScreen} />
          )}
        </SprintArea>
        <Animation active={this.state.loggedIn} type={ANIMATION_TYPES.fade}>
          <SprintBar
            parameters={otherParameters["sprintBar"]}
            helpClick={this.openHelp}
            skipClick={this.openSkip}
            points={this.state.currentPoints}
            maxPoints={this.state.maxPoints}
            level={this.state.level + 1}
            maxLevels={this.state.exerciseGroups.length}
          />
        </Animation>
        {CONFIG.SHOW_CONSENT && <SprintCookieConsent />}
      </div>
    )
  }

  changeComponent = (next = true, finish = false) => {
    let level = this.state.level
    let replaying = this.state.replaying
    let sprintFinished = this.state.sprintFinished
    let sendResults = this.state.sendResults
    let showFinish = this.state.showFinish
    let showSummary = this.state.showSummary

    if (this.state.loggedIn) {
      if (next) {
        if (finish) {
          sprintFinished = true
        } else {
          if (this.state.replaying) {
            replaying = false
            level = this.state.exerciseGroups.length - 1
          } else if (level < this.state.exerciseGroups.length - 1) {
            level++
          } else {
            if (!sprintFinished) {
              sprintFinished = true
              if (
                (!this.state.shouldShowSummary || !this.canRepeatExercises) &&
                this.state.shouldSendResults &&
                !sendResults
              ) {
                sendResults = true
              } else if (this.state.shouldShowSummary) {
                showSummary = true
              } else {
                showFinish = true
              }
            } else if (this.state.shouldSendResults && !sendResults) {
              sendResults = true
            } else if (this.state.shouldShowSummary && !showSummary) {
              showSummary = true
            } else if (!this.state.playInLoop) {
              showFinish = true
            } else {
              sprintFinished = false
              sendResults = false
              this._loop()
            }

            level = this.state.exerciseGroups.length - 1
          }
        }
      }
    }

    this.setState({
      level: level,

      showSummary,
      replaying: replaying,
      sprintFinished: sprintFinished,
      sendResults: sendResults,
      showFinish: showFinish,
    })
  }

  closeHelp = () => {
    this.setState({
      helpVisible: false,
    })
  }

  openHelp = () => {
    this.setState({
      helpVisible: true,
    })
  }

  closeSkip = () => {
    this.setState({
      skipVisible: false,
    })
  }

  openSkip = () => {
    this.setState({
      skipVisible: true,
    })
  }

  getCurrentExercise = () => {
    return this.state.exerciseGroups[this.state.level]
  }

  _replayExercise = (levelNumber) => {
    this.setState((prevState) => {
      prevState.currentPoints -= prevState.points[levelNumber].current
      prevState.points[levelNumber].current = 0

      return {
        currentPoints: prevState.currentPoints,
        points: prevState.points,

        replaying: true,
        level: parseInt(levelNumber, 10),
      }
    })
    this.changeComponent(false)
  }

  _loop = () => {
    this.setState((prevState) => {
      for (let pointsIndex in prevState.points) {
        if (prevState.points.hasOwnProperty(pointsIndex)) {
          prevState.points[pointsIndex].current = 0
        }
      }

      return {
        currentPoints: 0,
        points: prevState.points,

        level: STARTING_LEVEL,
        sprintFinished: false,
        sendResults: false,
        replaying: false,
      }
    })
  }

  sendGAEvent = (category, action, label, value) => {
    const newEvent = {
      category,
      action,
      label,
      value,
    }

    if (!isEqual(this.lastEvent, newEvent)) {
      this.lastEvent = newEvent

      if (CONFIG.GA_ID) {
        ReactGA.event({
          project: this.instanceName,
          sprint: this.sessionToken,
          ...newEvent,
        })
      }
    }
  }

  _onLoggedIn(username) {
    if (CONFIG.GA_ID) {
      ReactGA.set({ userId: window.localStorage.getItem("username") })
      this.sendGAEvent(GA_CATEGORIES.auth, "Logged in")
    }

    this.setState({
      loggedIn: true,
    })
    this.changeComponent(false)
  }

  _onNextScreen(results) {
    let currentExercise = this.getCurrentExercise()

    if (!_.isObject(results)) {
      let newResults = new Results()
      newResults.points = results
      results = newResults
    }

    if (results.points && results.points > 0) {
      this.setState((prevState) => {
        prevState.points[prevState.level].current += results.points
        return {
          points: prevState.points,
          currentPoints: prevState.currentPoints + results.points,
        }
      })
    }

    if (results.other) {
      this.setState((prevState) => {
        prevState.otherResults[currentExercise["id"]] = results.other

        return {
          otherResults: prevState.otherResults,
        }
      })
    }

    if (results.events) {
      this.setState((state) => {
        return {
          events: {
            ...state.events,
            [currentExercise["id"]]: results.events,
          },
        }
      })
    }

    this.changeComponent()
  }

  _generateExercisesSummary = () => {
    if (this.instanceScore.length > 0) {
      return [
        {
          name: "Wynik w tej sesji",
          points: this.state.currentPoints,
          maxPoints: this.state.maxPoints,
        },
      ].concat(this.instanceScore)
    } else {
      return this._generateExercisesPoints()
    }
  }

  _generateExercisesPoints = () => {
    let summary = []
    let points = this.state.points
    for (let levelIndex in points) {
      if (points.hasOwnProperty(levelIndex)) {
        if (points[levelIndex].max > 0) {
          summary.push({
            levelNumber: levelIndex,
            points: points[levelIndex].current,
            maxPoints: points[levelIndex].max,
            name: this.state.exerciseGroups[levelIndex].name,
          })
        }
      }
    }

    return summary
  }

  _generateShortPointsSummaryString = () => {
    let shortSummary = ""
    let summary = this._generateExercisesSummary()
    for (let exercise of summary) {
      shortSummary += exercise.name + ": " + exercise.points + " / " + exercise.maxPoints + "<br>"
    }

    return shortSummary
  }

  _onResize() {
    if (PlayArea.isVertical()) {
      this._resize(10 / 16)
    } else {
      this._resize(16 / 10)
    }
  }

  _resize = (proportions) => {
    let newWidth = window.innerWidth
    let newHeight = window.innerHeight - 40

    if (newWidth > newHeight * proportions) {
      newWidth = newHeight * proportions
    } else {
      newHeight = newWidth / proportions
    }

    newWidth -= 30
    newHeight -= 30

    this.setState({
      width: newWidth,
      height: newHeight,
      fontSize: newWidth / PlayArea.widthInEms(),
    })
  }

  _getExerciseComponent(data) {
    const { variant } = data.parameters
    this.doubleTapControlled = false

    if (!data) {
      return
    }

    const parameters = nestStringProperties(data.parameters)
    if (
      this.parameters["shuffleQuestions"] &&
      Array.isArray(data.questions) &&
      data.questions.length > 1
    ) {
      data.questions = shuffle(data.questions)
    }

    if (data.type === "login") {
      const { numericPassword, passwordForGeneratedLogin } = this.state.otherParameters

      return (
        <LoginScreen
          loginAction={this._onLoggedIn}
          serverAddress={SERVER_ADDRESS}
          shouldCreateUser={!this.state.passwordRequired || passwordForGeneratedLogin}
          generateLoginIfNeeded={this.state.generateLogin}
          numericPassword={numericPassword}
          passwordForGeneratedLogin={passwordForGeneratedLogin}
          forceLogin={this.forceLogin}
        />
      )
    } else if (data.type === "summary") {
      let calculatePercentage, percentageToPass
      if (this.state.otherParameters.summary) {
        calculatePercentage = this.state.otherParameters.summary.calculatePercentage
        percentageToPass = this.state.otherParameters.summary.percentageToPass
      }

      return (
        <SummaryScreen
          data={this._generateExercisesSummary()}
          fullPoints={this.state.currentPoints}
          maxPoints={this.state.maxPoints}
          canRepeatExercises={this.canRepeatExercises}
          parameters={defaultTo(this.state.otherParameters.summary, {})}
          calculatePercentage={calculatePercentage}
          percentageToPass={percentageToPass}
          nextAction={this._onNextScreen}
          replayAction={this._replayExercise}
        />
      )
    } else if (data.type === "send_results") {
      return (
        <SendResultsScreen
          serverAddress={SERVER_ADDRESS}
          exercisesPoints={this._generateExercisesPoints()}
          sessionToken={this.sessionToken}
          instanceName={this.instanceName}
          otherContent={this.state.otherResults}
          events={this.state.events}
          goNextAction={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "memory") {
      return (
        <MemoryExercise
          key={data.id}
          questions={data.questions}
          parameters={data.parameters}
          goNextAction={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "message") {
      return (
        <MessageScreen
          parameters={data.parameters}
          key={data.id}
          goNextAction={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "finish_message") {
      return (
        <FinishMessage
          parameters={data.parameters}
          key={data.id}
          goNextAction={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "shooting") {
      this.touchScrolling = true

      return (
        <ShootingExercise
          parameters={data.parameters}
          questions={data.questions}
          tutorial={data.tutorial}
          tutorialMessages={data.parameters.tutorialMessages}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "tiles") {
      let timePerQuestionSeconds
      if (data.parameters["timePerQuestionSeconds"] !== undefined) {
        timePerQuestionSeconds = parseInt(data.parameters["timePerQuestionSeconds"])
      }
      return (
        <TilesExercise
          questions={data.questions}
          parameters={parameters}
          timePerQuestionSeconds={timePerQuestionSeconds}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "rise_fall") {
      return (
        <RiseFallExercise
          parameters={data.parameters}
          questions={data.questions}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "train") {
      return (
        <TrainExercise
          parameters={data.parameters}
          questions={data.questions}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "choices") {
      return (
        <ChoicesExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "reveal") {
      return (
        <RevealExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "elevator") {
      return (
        <ElevatorExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "doors") {
      return (
        <DoorsExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "open-question") {
      return (
        <OpenQuestionExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "match") {
      return (
        <MatchExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "cauldron") {
      return (
        <CauldronExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else if (data["slug"] === "voting") {
      if (variant === "szymon") {
        return (
          <SzymonVotingExercise
            exerciseId={data.id}
            questions={data.questions}
            parameters={data.parameters}
            key={data.id}
            onFinish={this._onNextScreen}
          />
        )
      } else if (variant === "team-dysfunction") {
        return (
          <TeamDysfunctionsVotingExercise
            exerciseId={data.id}
            questions={data.questions}
            parameters={data.parameters}
            key={data.id}
            onFinish={this._onNextScreen}
          />
        )
      } else {
        return (
          <VotingExercise
            questions={data.questions}
            parameters={data.parameters}
            key={data.id}
            onFinish={this._onNextScreen}
          />
        )
      }
    } else if (data["slug"] === "parachutes") {
      return (
        <ParachutesExercise
          questions={data.questions}
          parameters={data.parameters}
          key={data.id}
          onFinish={this._onNextScreen}
        />
      )
    } else {
      return "exercise"
    }
  }
}

export const LoadingSprint = () => {
  let proportions

  if (PlayArea.isVertical()) {
    proportions = 10 / 16
  } else {
    proportions = 16 / 10
  }

  let width = window.innerWidth
  let height = window.innerHeight - 40

  if (width > height * proportions) {
    width = height * proportions
  } else {
    height = width / proportions
  }

  width -= 30
  height -= 30
  let fontSize = width / PlayArea.widthInEms()

  return (
    <div
      className={clsx("Sprint loading", {
        vertical: PlayArea.isVertical(),
        horizontal: !PlayArea.isVertical(),
      })}
    >
      <SprintArea
        slug="loading"
        className={clsx("game", "scrollable", "loading")}
        style={{
          fontSize,
          width,
          height,
        }}
      >
        <div />
      </SprintArea>
      <div className="loading-bar" />
    </div>
  )
}

function doc_keyUp(e) {
  // testing for alt + n
  if (e.altKey && (e.keyCode === 78 || e.keyCode === 77)) {
    CHANGE_COMPONENT()
  }
}
// register the handler
document.addEventListener("keyup", doc_keyUp, false)

export default Sprint
