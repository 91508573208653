import {useWindowSize} from "react-use";
import {useCallback, useEffect, useState} from "react";
import {singletonHook} from 'react-singleton-hook';
import PlayArea from "../../lib/PlayArea";

export interface GameSizeI {
  width: number
  height: number
  proportions: number
  font: number
  isVertical: boolean
}

const SPRINT_BAR_HEIGHT = 40
const GAME_AREA_PADDING = 0


export const MAX_WIDER = 1600
export const MAX_NARROWER = 1000

const INITIAL_STATE = {
  width: 0,
  height: 0,
  proportions: 0,
  font: 0,
  isVertical: false,
}

const useGameSize = singletonHook(INITIAL_STATE, (): GameSizeI => {
  const {width, height} = useWindowSize();
  const [size, setSize] = useState<GameSizeI>(INITIAL_STATE)

  const resize = useCallback((proportions: number) => {
    let newWidth = width - GAME_AREA_PADDING
    let newHeight = height - GAME_AREA_PADDING - SPRINT_BAR_HEIGHT

    if (newWidth > newHeight * proportions) {
      newWidth = newHeight * proportions;
    } else {
      newHeight = newWidth / proportions;
    }

    setSize({
      width: newWidth,
      height: newHeight,
      proportions: newWidth > newHeight ? newHeight / MAX_NARROWER : newWidth / MAX_NARROWER,
      font: newWidth / PlayArea.widthInEms(),
      isVertical: PlayArea.isVertical(),
    })
  }, [width, height]);

  useEffect(() => {
    if (PlayArea.isVertical()) {
      resize(10/16);
    } else {
      resize(16/10);
    }
  }, [resize])


  return size
})

export default useGameSize