import React from "react"
import { shuffle, defaultTo } from "lodash"

import ExerciseComponent from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import InstructionCard from "components/InstructionCard/InstructionCard"
import Cauldron from "./subcomponents/Cauldron"
import CauldronAnswers from "./subcomponents/CauldronAnswers"

import Sounds from "lib/Sounds/Sounds"
import AnimationCorrectExplosion from "animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import AnimationIncorrectExplosion from "animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion"
import FeedbackCard from "components/FeedbackCard/FeedbackCard"
import { DEFAULT_STATES } from "base/ExerciseComponent"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import Button from "../../components/Button/Button"
import CONFIG from "config"
import { INSTRUCTION_STEP_TYPES } from "../../base/subcomponents"
import exerciseImg from "./img/exercise.jpg"
import exerciseVerticalImg from "./img/exercise-vertical.jpg"
import { withTranslation } from "react-i18next"

import "./CauldronExercise.scss"
import { DEFAULT_ANIMATION_SPEED } from "app/Sprint"
import Card from "../../components/Card/Card"

const DEFAULT_POINTS_PER_ANSWER = 2
const DEFAULT_POINTS_PER_INCORRECT_ANSWER = 1
const DEFAULT_AVAILABLE_TRIES = 3
const DEFAULT_TIME_LIMIT_S = 60
const DEFAULT_IS_LEARNING = false
const BREWING_ANIMATION_SPEED_MS = 3000

const TIME_TO_READ_QUESTION_S = 3

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  ANSWERING: 2,
  SHOWING_FEEDBACK: 3,
  QUESTION_FINISHING: 4,
  QUESTION_FINISHED: 5,
  QUESTION_CHANGING: 6,
}

class CauldronExercise extends ExerciseComponent {
  static exerciseClass = "CauldronExercise"
  questions = []
  negativePointsAllowed = true

  pointsPerAnswer = DEFAULT_POINTS_PER_ANSWER
  pointsPerIncorrectAnswer = DEFAULT_POINTS_PER_INCORRECT_ANSWER
  availableTries = DEFAULT_AVAILABLE_TRIES
  isLearning = DEFAULT_IS_LEARNING
  hidePoints = DEFAULT_IS_LEARNING
  feedbackDisplayTime = CONFIG.TIME_TO_READ_FEEDBACK_MS

  initInstructions(props) {
    const { t, parameters } = props

    const tries = defaultTo(parameters["availableTries"], DEFAULT_AVAILABLE_TRIES)
    const pointsCorrect = defaultTo(parameters["pointsPerAnswer"], DEFAULT_POINTS_PER_ANSWER)
    const pointsIncorrect = defaultTo(
      parameters["pointsPerIncorrectAnswer"],
      DEFAULT_POINTS_PER_INCORRECT_ANSWER
    )

    let instruction_points = t("instruction_points_correct", {
      count: pointsCorrect,
    })
    if (pointsIncorrect > 0) {
      instruction_points =
        instruction_points + " " + t("instruction_points_incorrect", { count: pointsIncorrect })
    }

    this.instruction = t("game_instruction")
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices", {
          count: tries,
        }),
        [INSTRUCTION_STEP_TYPES.POINTS]: instruction_points,
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    }
  }

  static maxPoints(questions, parameters) {
    const isLearning = defaultTo(parameters["isLearning"], DEFAULT_IS_LEARNING)

    if (isLearning) {
      return 0
    }

    let points = 0
    const pointsPerAnswer = defaultTo(parameters["pointsPerAnswer"], DEFAULT_POINTS_PER_ANSWER)

    for (let question of questions) {
      for (let answer of question.answers) {
        if (answer.correct) {
          points += pointsPerAnswer
        }
      }
    }

    return points
  }

  constructor(props) {
    super(props)

    const { questions, parameters } = this.props

    this.questions = questions
    const question = questions[0]

    this.availableTries = defaultTo(parameters["availableTries"], DEFAULT_AVAILABLE_TRIES)

    this.state = {
      ...this.state,

      exploding: false,
      brewed: false,

      question: {
        index: 0,
        current: question,
      },
      selectedAnswer: undefined,
      answers: this.prepareAnswers(question.answers),
      tries: this.availableTries,

      dragged: {
        position: undefined,
        answer: undefined,
      },

      feedback: {
        position: undefined,
        correct: undefined,
        message: "",
      },
    }

    this.feedbackDisplayTime = defaultTo(
      parameters["feedbackDisplayTimeS"] * 1000,
      DEFAULT_IS_LEARNING
    )
    this.hidePoints = this.isLearning = defaultTo(parameters.isLearning, DEFAULT_IS_LEARNING)
    this.pointsPerAnswer = this.isLearning
      ? 0
      : defaultTo(parameters["pointsPerAnswer"], DEFAULT_POINTS_PER_ANSWER)
    this.pointsPerIncorrectAnswer = this.isLearning
      ? 0
      : defaultTo(parameters["pointsPerIncorrectAnswer"], DEFAULT_POINTS_PER_INCORRECT_ANSWER)
    this.maxPoints = CauldronExercise.maxPoints(questions, parameters)
    this.timePerQuestionSeconds = this.isLearning
      ? 0
      : defaultTo(parameters.timePerQuestionSeconds, DEFAULT_TIME_LIMIT_S)
  }

  prepareAnswers = (answers) => {
    return shuffle(answers).map((answer, index) => ({
      index,
      id: answer.id,
      content: answer.content,
      correct: answer.correct,
      feedback: answer.parameters.feedback,
      active: true,
    }))
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.ANSWERING],
        },
        questions,
        parameters
      ),
    ]
  }

  renderExercise(state, props) {
    const { question, answers, dragged, feedback, exploding, brewed, tries } = state
    const { t } = props

    let currentShake = undefined
    if (this.availableTries > 1 && tries === 1) {
      currentShake = "shake"
    } else if (this.availableTries > 2 && tries === 2) {
      currentShake = "shake-little"
    }

    return (
      <>
        <InstructionCard
          visible={this.inStates([
            STATES.QUESTION_APPEARING,
            STATES.ANSWERING,
            STATES.SHOWING_FEEDBACK,
          ])}
          mainText={question.current.content}
          markdown
          small
        />
        <AnimatedElement visible={this.inStates([STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}>
          <AnimatedElement
            visible={brewed || exploding}
            className="finish-feedback-container"
            zIndex={10}
          >
            <Card
              className="finish-feedback"
              color={brewed ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}
            >
              {brewed ? "Świetnie! :)" : "To była mieszanka wybuchowa..."}
            </Card>
          </AnimatedElement>

          <CauldronAnswers
            answers={answers}
            setDraggedPosition={this.setDraggedPosition}
            disabled={this.inState(STATES.SHOWING_FEEDBACK) || brewed || exploding}
          />

          <Cauldron
            draggedPosition={dragged.position}
            onHit={this.answerSelected}
            exploding={exploding}
            shake={currentShake}
            brewed={brewed}
          />

          <AnimationCorrectExplosion
            fixed
            visible={this.inState(STATES.SHOWING_FEEDBACK) && feedback.correct}
            position={feedback.position}
          />
          <AnimationIncorrectExplosion
            fixed
            visible={this.inState(STATES.SHOWING_FEEDBACK) && !feedback.correct}
            position={feedback.position}
          />
          <FeedbackCard
            visible={this.inState(STATES.SHOWING_FEEDBACK)}
            content={feedback.message}
            successful={feedback.correct}
            useDefaultFeedback={false}
            onFinished={this.hideFeedback}
          />
        </AnimatedElement>
        <AnimatedElement
          className="next-question"
          visible={this.inState(STATES.QUESTION_FINISHED)}
          animation={AnimatedElement.AnimationTypes.popOut}
        >
          <Button onClick={this.showNextQuestion} big>
            {t("common:continue")}
          </Button>
        </AnimatedElement>
      </>
    )
  }

  startGame = () => {
    super._questionAppeared(this.state.question)
    this.setCurrentStateSequence(
      [STATES.QUESTION_APPEARING, STATES.ANSWERING],
      TIME_TO_READ_QUESTION_S * 1000
    )
  }

  isClockRunning = () => {
    return this.inState(STATES.ANSWERING)
  }

  setDraggedPosition = (position, answer) => {
    this.setState({
      dragged: {
        position,
        answer,
      },
    })
  }

  answerSelected = () => {
    let currentAnswer

    this.setState(
      (state) => {
        const answers = [...state.answers]
        const { dragged, points } = state
        let { tries } = state
        const { position, answer } = dragged
        let correct = answer.correct
        let message = answer.feedback
        currentAnswer = answer
        let pointsChange
        let exploding = false

        answers[answer.index].active = false

        if (answer.correct) {
          Sounds.success.play()
          pointsChange = this.pointsPerAnswer
        } else {
          Sounds.error.play()
          pointsChange = -this.pointsPerIncorrectAnswer

          if (!this.isLearning) {
            tries--
            if (tries === 0) {
              exploding = true
            }
          }
        }

        const feedback = {
          position,
          message,
          correct,
          exploding,
        }

        super._answerChosen(answer, { correct })

        return {
          feedback,
          exploding,
          tries,
          selectedAnswer: currentAnswer,
          dragged: {
            answer: undefined,
            position: undefined,
          },

          answers,
          points: points + pointsChange,
        }
      },
      () => {
        this.setCurrentState(STATES.SHOWING_FEEDBACK)
      }
    )
  }

  hideFeedback = () => {
    const { selectedAnswer } = this.state
    this.setCurrentState(STATES.ANSWERING, this.checkIfQuestionFinished$(selectedAnswer))
  }

  checkIfQuestionFinished$ = (answer$) => () => {
    const { answers, tries, exploding } = this.state
    let finished = true

    if (this.isLearning || answer$.correct || tries > 0) {
      for (const answer of answers) {
        if (answer.correct && answer.active) {
          finished = false
          break
        }
      }
    }

    if (finished) {
      this.setState(
        {
          brewed: !exploding,
        },
        () => {
          setTimeout(this.questionFinished, BREWING_ANIMATION_SPEED_MS)
        }
      )
    }
  }

  timeRanOut = () => {
    this.questionFinished()
  }

  questionFinished = () => {
    const index = this.state.question.index

    if (index + 1 >= this.questions.length) {
      this.setCurrentStateSequence(
        [STATES.QUESTION_FINISHING, STATES.FINISHING],
        DEFAULT_ANIMATION_SPEED
      )
    } else {
      this.setCurrentStateSequence(
        [STATES.QUESTION_FINISHING, STATES.QUESTION_FINISHED],
        DEFAULT_ANIMATION_SPEED
      )
    }
  }

  showNextQuestion = () => {
    this.currentQuestionIndex++

    this.setState(
      (state) => {
        const index = state.question.index + 1
        const question = { ...this.questions[index] }

        return {
          question: {
            current: question,
            index,
          },
          exploding: false,
          brewed: false,
          tries: this.availableTries,
          answers: this.prepareAnswers(question.answers),
        }
      },
      () => {
        super._questionAppeared(this.state.question)
        this.setCurrentStateSequence(
          [STATES.QUESTION_CHANGING, STATES.QUESTION_APPEARING],
          CONFIG.ANIMATION_SPEED_MS,
          () => {
            this.setCurrentStateDelayed(STATES.ANSWERING, TIME_TO_READ_QUESTION_S * 1000)
          }
        )
      }
    )
  }
}

export default withTranslation(["exercises/cauldron", "common"])(CauldronExercise)
