import create from "zustand"

export const SIDES = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
}

const DEFAULT_STATE = {
  sides: {
    [SIDES.LEFT]: false,
    [SIDES.RIGHT]: false,
    [SIDES.TOP]: false,
    [SIDES.BOTTOM]: false,
  },
}

export const useSprintState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),

  resetSides: () => {
    set((s) => ({
      ...s,
      sides: {
        ...DEFAULT_STATE.sides,
      },
    }))
  },
  setSides: (sides) => {
    set((s) => ({
      ...s,
      sides: {
        ...sides,
      },
    }))
  },
}))
