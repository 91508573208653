import { useCallback } from "react"
import Sounds from "../../lib/Sounds"
import { shuffle, some, filter } from "lodash"
import useEffectOnce from "react-use/lib/useEffectOnce"
import { usePointsState } from "./$points"
import create from "zustand"
import { POINT_TYPES, QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"

const DEFAULT_STATE = {
  answers: [],
  should_show_feedback: false,
  all_tries_finished: false,
  finish_type: QUESTION_FINISH_TYPES.FIRST_INCORRECT,
  last_answer: {
    id: 0,
    correct: true,
    feedback: "",
    parameters: {},
  },
}

export const useAnswersState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),

  questionChanged: (new_question) =>
    set({
      answers: shuffle(new_question.answers).map((answer) => ({
        ...answer,
        answered: false,
      })),
    }),
  answerChosen: (answer) =>
    set((s) => {
      const new_answers = s.answers.map((mappedAnswer) => ({
        ...mappedAnswer,
        answered: answer.id === mappedAnswer.id ? true : mappedAnswer.answered,
      }))

      let is_finished = false
      switch (s.finish_type) {
        case QUESTION_FINISH_TYPES.FIRST_INCORRECT:
          is_finished = !answer.correct || !some(new_answers, { correct: true, answered: false })
          break
        case QUESTION_FINISH_TYPES.FIRST_CORRECT:
          is_finished = answer.correct || !some(new_answers, { answered: false })
          break
        case QUESTION_FINISH_TYPES.ALL_ANSWERS:
          is_finished = !some(new_answers, { answered: false })
          break
        case QUESTION_FINISH_TYPES.ALL_CORRECT:
          is_finished = !some(new_answers, { answered: false, correct: true })
          break
        case QUESTION_FINISH_TYPES.FIRST:
        default:
          is_finished = true
          break
      }

      set({
        answers: new_answers,
        all_tries_finished: is_finished,
        last_answer: {
          id: answer.id,
          correct: answer.correct,
          parameters: answer.parameters,
          feedback:
            s.should_show_feedback && answer.parameters.feedback
              ? answer.parameters.feedback
              : false,
        },
      })
    }),
  extendAnswers: (extended_values = {}) =>
    set((s) => ({
      answers: s.answers.map((answer) => ({
        ...answer,
        ...extended_values,
      })),
    })),
  updateAnswer: (id, updated_values) => {
    set((s) => ({
      answers: s.answers.map((answer) => {
        if (answer.id === id) {
          return { ...answer, ...updated_values }
        } else {
          return { ...answer }
        }
      }),
    }))
  },
}))

export const useAnswers = (config) => {
  const { answers: answers_cfg, points: points_cfg } = config.parameters
  const {
    answers,
    all_tries_finished,
    resetState,
    answerChosen,
    questionChanged,
    last_answer,
  } = useAnswersState()
  const { changePoints } = usePointsState()

  const { sounds: should_play_sound } = answers_cfg
  const { per_correct_answer, per_incorrect_answer } = points_cfg

  useEffectOnce(() => {
    const { feedback: should_show_feedback, finish_type } = answers_cfg

    resetState({ should_show_feedback, finish_type })
    questionChanged(config.questions[0])

    return () => resetState()
  })

  const innerAnswerChosen = useCallback(
    (answer) => {
      if (should_play_sound) {
        answer.correct ? Sounds.success.play() : Sounds.error.play()
      }

      let correct_points = per_correct_answer

      if (isNaN(correct_points)) {
        switch (correct_points) {
          case POINT_TYPES.ANSWERS_LEFT_COUNT:
            correct_points = filter(answers, { answered: false }).length - 1
            break
          case POINT_TYPES.PER_ANSWER:
            correct_points = answer.parameters.points ?? 2
            break
          default:
            break
        }
      }

      changePoints(answer.correct ? correct_points : per_incorrect_answer)
      answerChosen(answer)
    },
    [
      should_play_sound,
      per_correct_answer,
      per_incorrect_answer,
      answers,
      answerChosen,
      changePoints,
    ]
  )

  return {
    answers,
    all_tries_finished,

    answerChosen: innerAnswerChosen,
    questionChanged,
    last_answer,
  }
}
