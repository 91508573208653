import {useEffect, useRef, useState} from "react";
import defaultTo from "lodash/defaultTo";

import useGameSize from "./useGameSize";

export interface GamePositionI {
  x: number
  y: number
  width: number
  height: number
}

export interface GamePositionParameterI {
  x?: [number, number]
  y?: [number, number]
  width?: [number, number]
  height?: [number, number]
}

function useGamePosition({x=[0, 0], y=[0, 0], width=[0, 0], height=[0, 0]}: GamePositionParameterI) : GamePositionI {
  const {proportions, isVertical} = useGameSize()
  const [position, setPosition] = useState<GamePositionI>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const basePosition = useRef<GamePositionParameterI>({
    x,
    y,
    width,
    height,
  })

  useEffect(() => {
    const index = isVertical ? 1 : 0

    setPosition({
      x: defaultTo(basePosition.current.x, [0, 0])[index] * proportions,
      y: defaultTo(basePosition.current.y, [0, 0])[index] * proportions,
      width: defaultTo(basePosition.current.width, [0, 0])[index] * proportions,
      height: defaultTo(basePosition.current.height, [0, 0])[index] * proportions,
    })
  }, [isVertical, proportions])

  return position
}

export default useGamePosition