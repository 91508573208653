import AnimatedElement from "../AnimatedElement/AnimatedElement"
import React from "react"

export const ANIMATIONS = AnimatedElement.AnimationTypes

const Element = ({className, active, animation, children}) => {
  // TODO change to functional component
  return <AnimatedElement
    className={className}
    visible={active}
    animation={animation}
  >
    {children}
  </AnimatedElement>
}

export default Element