import React, { useState, useEffect, useRef, useCallback } from "react"

import Card from "components/Card/Card"
import PlayArea from "lib/PlayArea"

import { ReactComponent as targetIcon } from "../images/icons/target.svg"
import { ReactComponent as executionIcon } from "../images/icons/execution.svg"
import { ReactComponent as choicesIcon } from "../images/icons/choices.svg"
import { ReactComponent as pointsIcon } from "../images/icons/points.svg"

import "./ExerciseInstructions.scss"
import ReactMarkdown from "react-markdown"
import Button from "../../components/Button"
import useToggle from "react-use/lib/useToggle"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

export const INSTRUCTION_STEP_TYPES = {
  TARGET: "TARGET",
  EXECUTION: "EXECUTION",
  CHOICES: "CHOICES",
  POINTS: "POINTS",
}

const STEP_IMAGES = {
  [INSTRUCTION_STEP_TYPES.TARGET]: targetIcon,
  [INSTRUCTION_STEP_TYPES.EXECUTION]: executionIcon,
  [INSTRUCTION_STEP_TYPES.CHOICES]: choicesIcon,
  [INSTRUCTION_STEP_TYPES.POINTS]: pointsIcon,
}
const STEP_TIME_MS = 2500

let timeout

export default function ExerciseInstructions({
  name,
  steps,
  imageHorizontal,
  imageVertical,
  onFinish,
}) {
  const { t } = useTranslation("common")
  const [currentStep, setCurrentStep] = useState(0)
  const currentStepRef = useRef(currentStep)
  const [isFinished, toggleFinished] = useToggle(false)
  currentStepRef.current = currentStep

  const goToNextStep = useCallback(() => {
    if (currentStepRef.current >= Object.values(steps).length) {
      toggleFinished()
    } else {
      const nextStep = currentStepRef.current + 1
      setCurrentStep(nextStep)
    }
  }, [steps, toggleFinished])

  useEffect(() => {
    timeout = setTimeout(goToNextStep, currentStep === 0 ? 500 : STEP_TIME_MS)

    return () => {
      clearTimeout(timeout)
    }
  }, [currentStep, goToNextStep])

  return (
    <div className="ExerciseInstructions">
      <Card>
        <div className="name">{name}</div>
        <div className="content">
          <img
            src={PlayArea.isVertical() ? imageVertical : imageHorizontal}
            alt="Ćwiczenie"
            className="exercise-image"
          />
          <div className="instructions">
            {Object.entries(steps).map(([type, content], index) => (
              <ExerciseInstructionStep
                key={index}
                type={type}
                content={content}
                visible={index < currentStep}
              />
            ))}
          </div>
        </div>
      </Card>
      <Button
        className={clsx("start-button", {
          "instructions-showing": !isFinished,
        })}
        onClick={onFinish}
        big
      >
        {isFinished ? t("begin") : t("skip")}
      </Button>
    </div>
  )
}

function ExerciseInstructionStep({ type, content, visible }) {
  const StepImage = STEP_IMAGES[type]

  return (
    <div className={clsx("ExerciseInstructionStep", { "not-shown": !visible })}>
      <StepImage className="step-image" />
      <ReactMarkdown source={content} />
    </div>
  )
}
