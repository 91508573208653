import React from "react"
import { defaultTo } from "lodash"

import ExerciseComponent, { DEFAULT_STATES } from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import CONFIG from "config"

import "./ParachutesExercise.scss"
import ParachutesCard from "./subcomponents/ParachutesCard"
import ParachutesCategories from "./subcomponents/ParachutesCategories"
import Sounds from "../../../lib/Sounds/Sounds"
import AnimationCorrectExplosion from "../../../animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import AnimationIncorrectExplosion from "../../../animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion"
import FeedbackCard from "../../../components/FeedbackCard/FeedbackCard"
import ChosenAnswerStatsModule from "../../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { withTranslation } from "react-i18next"
import { INSTRUCTION_STEP_TYPES } from "../../../base/subcomponents"
import exerciseImg from "./img/exercise.jpg"
import exerciseVerticalImg from "./img/exercise-vertical.jpg"

const ANIMATION_SPEED = CONFIG.ANIMATION_SPEED_MS
const POINTS_PER_QUESTION = 2

const STATES = {
  ...DEFAULT_STATES,
  STARTED: 1,
  QUESTION_STARTING: 2,
  QUESTION_ANSWERING: 3,
  QUESTION_BETWEEN_ANSWERS: 4,
  QUESTION_SHOWING_FEEDBACK: 5,
  QUESTION_CHANGING: 6,
}

const FALL_DURATION_SPEED_S = {
  fast: 10,
  medium: 15,
  slow: 20,
}

const DEFAULT_PARAMETERS = {
  timePerQuestionSeconds: 120,
  fallDurationS: FALL_DURATION_SPEED_S.medium,
}

class ParachutesExercise extends ExerciseComponent {
  static exerciseClass = "ParachutesExercise"

  categoriesRef = React.createRef()
  parachuteCardRef = React.createRef()
  questionIndex = 0
  fallDurationS
  answers = []
  recycle = false

  initInstructions(props) {
    const { t, parameters } = props
    const { hidePoints } = parameters

    this.instruction = t("game_instruction")
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: hidePoints
          ? t("instruction_hidden_points")
          : t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    }
  }

  static maxPoints(questions) {
    let points = 0

    questions.forEach((question) => (points += question.answers.length * POINTS_PER_QUESTION))

    return points
  }

  chosenAnswerIds = []
  timeout = null

  constructor(props) {
    super(props)
    const { parameters, questions } = props

    this.state = {
      ...this.state,
      count: 0,
      question: undefined,
      answer: undefined,
      categories: undefined,
      feedback: {
        animation: {
          position: [0, 0],
        },
        message: "",
        correct: true,
      },
    }

    if (parameters["fallSpeed"]) {
      this.fallDurationS = FALL_DURATION_SPEED_S[parameters["fallSpeed"]]
    } else {
      this.fallDurationS = DEFAULT_PARAMETERS["fallDurationS"]
    }

    this.timePerQuestionSeconds = defaultTo(
      parameters.timePerQuestionSeconds,
      DEFAULT_PARAMETERS["timePerQuestionSeconds"]
    )
    this.maxPoints = ParachutesExercise.maxPoints(questions)

    ExerciseComponent.shuffleAnswers(this.state)
    ExerciseComponent.processAnswers(this.state, {
      points: POINTS_PER_QUESTION,
      round: 1,
      active: true,
    })

    this.onState(STATES.QUESTION_STARTING, this.questionStarting)
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.QUESTION_ANSWERING],
        },
        questions,
        parameters
      ),
    ]
  }

  renderExercise = (state, props) => {
    const { question, answer, categories, feedback, count } = state
    if (!question || !answer || !categories) {
      return null
    }

    return (
      <>
        <AnimatedElement
          visible={
            !this.inStates([
              DEFAULT_STATES.STARTING,
              DEFAULT_STATES.FINISHING,
              DEFAULT_STATES.FINISHED,
            ])
          }
        >
          <ParachutesExercise.Card
            count={count}
            answer={answer}
            innerRef={this.parachuteCardRef}
            fallDurationS={this.fallDurationS}
            onFallFinished={this.answerFell}
          />

          <AnimatedElement visible={!this.inStates([STATES.QUESTION_CHANGING])}>
            <ParachutesExercise.Categories
              categories={categories}
              categorySelected$={this.categorySelected$}
              disabled={this.inState(STATES.QUESTION_SHOWING_FEEDBACK)}
            />
          </AnimatedElement>

          <AnimationCorrectExplosion
            fixed
            visible={this.inState(STATES.QUESTION_SHOWING_FEEDBACK) && feedback.correct}
            position={feedback.animation.position}
          />
          <AnimationIncorrectExplosion
            fixed
            visible={this.inState(STATES.QUESTION_SHOWING_FEEDBACK) && !feedback.correct}
            position={feedback.animation.position}
          />

          <FeedbackCard
            visible={this.inState(STATES.QUESTION_SHOWING_FEEDBACK)}
            content={feedback.message}
            successful={feedback.correct}
            useDefaultFeedback={false}
            onFinished={this.closeFeedback}
          />
        </AnimatedElement>
      </>
    )
  }

  startGame = () => {
    this.setCurrentStateSequence([STATES.STARTED, STATES.QUESTION_STARTING], ANIMATION_SPEED)
  }

  questionStarting = () => {
    const { questions } = this.state
    const question = questions[this.questionIndex]

    this.answers = question.answers
    const answer = this.answers.shift()

    const categories = question.parameters.categories

    this.questionIndex++

    this._questionAppeared(question)

    this.setState(
      {
        question,
        categories,
        answer,
      },
      () => {
        this.setCurrentState(STATES.QUESTION_ANSWERING)
      }
    )
  }

  answerFell = () => {
    this.nextAnswer(true)
  }

  nextAnswer = (recycleQuestion = false) => {
    if (recycleQuestion) {
      const oldAnswer = {
        ...this.state.answer,
        points: Math.max(0, this.state.answer.points - 1),
        round: this.state.answer.round + 1,
        active: true,
      }

      this.answers.push(oldAnswer)
    }

    const answer = this.answers.shift()

    if (answer) {
      this.setState((state) => ({
        count: state.count + 1,
        answer,
      }))
    } else {
      const { questions } = this.state

      if (this.questionIndex > questions.length - 1) {
        this.setCurrentState(DEFAULT_STATES.FINISHING)
      } else {
        this.setCurrentStateSequence(
          [STATES.QUESTION_CHANGING, STATES.QUESTION_STARTING],
          ANIMATION_SPEED
        )
      }
    }
  }

  categorySelected = (id, categoryButtonRef) => {
    this.recycle = false

    this.setState(
      (state) => {
        const { points } = state
        let answer = {
          ...state.answer,
        }

        const parachuteRect = categoryButtonRef.current.buttonRef.current.getBoundingClientRect()
        const position = [
          (parachuteRect.x ?? parachuteRect.left) + parachuteRect["width"] / 2,
          (parachuteRect.y ?? parachuteRect.top) + parachuteRect["height"] / 2,
        ]

        let correct, message, pointsChange

        answer.active = false

        if (parseInt(answer.parameters.category) === id) {
          Sounds.success.play()
          pointsChange = answer.points
          correct = true
          message = answer.parameters.correctFeedback
        } else {
          Sounds.error.play()
          pointsChange = 0
          correct = false
          this.recycle = true
          message = answer.parameters.incorrectFeedback
        }

        const feedback = {
          animation: {
            position,
          },
          message,
          correct,
        }

        super._answerChosen(answer, {
          chosenAnswerOther: {
            round: answer.round,
          },
          correct,
        })

        return {
          answer,
          feedback,
          points: points + pointsChange,
        }
      },
      () => {
        this.setCurrentState(STATES.QUESTION_SHOWING_FEEDBACK)
      }
    )
  }

  closeFeedback = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERING, () => this.nextAnswer(this.recycle))
  }

  categorySelected$ = (id, categoryButtonRef) => () => this.categorySelected(id, categoryButtonRef)

  timeRanOut = () => {
    this.setCurrentState(DEFAULT_STATES.FINISHING)
  }

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING)
  }

  static Card = ParachutesCard
  static Categories = ParachutesCategories
}

export default withTranslation(["exercises/parachutes", "common"])(ParachutesExercise)
