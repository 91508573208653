import { INSTRUCTION_STEP_TYPES } from "base/subcomponents"
import { SIDES } from "../../state/$sprint"

export const QUESTION_FINISH_TYPES = {
  FIRST: "first",
  FIRST_INCORRECT: "first_incorrect",
  FIRST_CORRECT: "first_correct",
  ALL_ANSWERS: "all_answers",
  ALL_CORRECT: "all_correct",
}

export const POINT_TYPES = {
  ANSWERS_LEFT_COUNT: "answers_left_count",
  PER_ANSWER: "per_answer",
}

export const NEXT_QUESTION_TYPE = {
  NEXT: "next",
  IN_ANSWER: "in_answer",
}

const default_config = {
  parameters: {
    showInstructions: true,
    hasPostExerciseScreen: false,
    sides: {
      [SIDES.LEFT]: false,
      [SIDES.RIGHT]: false,
      [SIDES.TOP]: false,
      [SIDES.BOTTOM]: false,
    },
    points: {
      enabled: true,
      per_correct_answer: 2,
      per_incorrect_answer: 0,
    },
    time: {
      enabled: true,
      perQuestion: 60,
    },
    questions: {
      show: true,
      next: NEXT_QUESTION_TYPE.NEXT,
    },
    answers: {
      sounds: true,
      tries: 1,
      feedback: true,
      finish_type: QUESTION_FINISH_TYPES.FIRST_INCORRECT,
    },
  },
  images: {
    horizontal: undefined,
    vertical: undefined,
  },
  instructions: (t) => ({
    [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
    [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
    [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
    [INSTRUCTION_STEP_TYPES.POINTS]: t("instruction_points"),
  }),
  hints: (t) => [],
  maxPoints: function () {
    let points = 0
    const { per_correct_answer } = this.parameters.points

    for (let question of this.questions) {
      for (let answer of question.answers) {
        switch (per_correct_answer) {
          case POINT_TYPES.ANSWERS_LEFT_COUNT:
            points += 1
            break
          default:
            if (answer.correct) points += per_correct_answer
            break
        }
      }
      switch (per_correct_answer) {
        case POINT_TYPES.ANSWERS_LEFT_COUNT:
          points -= 1
          break
        default:
          break
      }
    }

    return points
  },
}

export default default_config
