import React, { Component } from "react"
import Card from "components/Card/Card"
import anime from "animejs"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactComponent as ParachuteSVG } from "../img/parachute.svg"

import "./ParachutesCard.scss"
import PlayArea from "../../../../lib/PlayArea"

export const STATES = {
  MOVING: 0,
  PAUSED: 1,
}

export default class ParachutesCard extends Component {
  static propTypes = {
    count: PropTypes.number,
    answer: PropTypes.object,
    fallDurationS: PropTypes.number,

    onFallFinished: PropTypes.func,

    innerRef: PropTypes.object,
  }

  animation

  componentDidMount() {
    this.restartAnimation()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.count !== this.props.count) {
      this.animation.restart()
    }

    if (prevProps.answer.active !== this.props.answer.active) {
      this.stateChanged(this.props.answer.active)
    }
  }

  stateChanged = (isActive) => {
    if (isActive) {
      this.animation.restart()
    } else {
      this.animation.pause()
    }
  }

  restartAnimation = () => {
    const { fallDurationS, innerRef, onFallFinished } = this.props

    let translateY = PlayArea.isVertical() ? "112rem" : "87rem"

    this.animation = anime({
      targets: innerRef.current,
      duration: fallDurationS * 1000,
      translateY,
      // easing: "easeInOutSine",
      easing: "cubicBezier(.5, .5, .9, .1)",
      complete: onFallFinished,
    })
  }

  render() {
    const { answer, innerRef } = this.props

    return (
      <div className="ParachutesCard">
        <Card className={classNames({ active: answer.active })} innerRef={innerRef}>
          <ParachuteSVG className="parachute" />
          {answer.content}
        </Card>
      </div>
    )
  }
}
