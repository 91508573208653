import Color from "color"
import scssVariables from "variables.scss"

const BASE_COLORS = {
  MAIN: scssVariables.main,
  BRIGHT: scssVariables.bright,
  CLICKABLE: scssVariables.clickable,

  SUCCESS: scssVariables.success,
  ERROR: scssVariables.error,

  WHITE: "#FFFFFF",
  BLACK: "#000000",
}

const COLORS = {
  ...BASE_COLORS,
  CLICKABLE_HOVER: Color(BASE_COLORS.CLICKABLE).lighten(0.2).hex(),

  FONT: scssVariables.font,
}

export default COLORS
